import { Trans, t } from "@lingui/macro";
import illustration from "../../../assets/pictures/illustrations/services/simple-website.webp";
import { CustomerNeedsCardProps } from "../../technical/technology-card/interfaces";

const BASIC_WEBSITE: CustomerNeedsCardProps = {
  id: "00-basic-website",
  comingSoon: false,
  illustration,
  illustrationAlt: t`Get your simple website.`,
  order: 0,
  needsList: (
    <ul>
      <li>
        <Trans>A website to promote your business.</Trans>
      </li>
      <li>
        <Trans>A contact form to get in touch.</Trans>
      </li>
      <li>
        <Trans>Some text and pictures to explain and show what you do.</Trans>
      </li>
      <li>
        <Trans>
          A map to show where you are located and when you are open.
        </Trans>
      </li>
      <li>
        <Trans>A display for your main services or products.</Trans>
      </li>
      <li>
        <Trans>Links to your social media accounts to stay connected.</Trans>
      </li>
    </ul>
  ),
  servicesList: (
    <ul>
      <li>
        <Trans>
          A simple static website which content should barely vary through time.
        </Trans>
      </li>
      <li>
        <Trans>
          A responsive design that looks good on all devices (smartphones,
          tablets, laptops, and desktops).
        </Trans>
      </li>
      <li>
        <Trans>Theme, colors, and fonts that match your brand identity.</Trans>
      </li>
      <li>
        <Trans>
          Dark mode and language translations to improve the user experience.
        </Trans>
      </li>
      <li>
        <Trans>
          A contact form to allow your visitors to send you a message and
          receive it directly by email.
        </Trans>
      </li>
    </ul>
  ),
};

export default BASIC_WEBSITE;

import { Trans } from "@lingui/macro";
import { Alert } from "@mui/material";
import javascriptLogo from "../../../assets/pictures/logos/technical/javascript-logo.png";
import nodeLogo from "../../../assets/pictures/logos/technical/node-logo.png";
import logo from "../../../assets/pictures/logos/technical/react-logo.png";
import typescriptLogo from "../../../assets/pictures/logos/technical/typescript-logo.png";
import { TechnologyCardProps } from "../technology-card/interfaces";

const REACT: TechnologyCardProps = {
  title: "React",
  order: 1,
  logo,
  subTitle: "JavaScript Web Frontend Framework",
  detailLogos: [nodeLogo, javascriptLogo, typescriptLogo],
  latestUsedVersion: "18",
  url: "react.dev",
  favorite: true,
  description: (
    <Trans>
      <p>
        <strong>React</strong> is a popular JavaScript/TypeScript library for
        building user interfaces, especially single-page applications.
      </p>
      <h3>How we use it</h3>
      <p>
        In our Full-Stack applications, we usually utilize a TypeScript React
        frontend connected to a Django backend, with a GraphQL API facilitating
        the interaction between them. The frontend application typically runs
        within a Node.js Docker container during development and is deployed in
        an Nginx container for production, based on the built artifact of the
        development container.
        <br />
        We maintain a clean codebase by strictly adhering to internal coding
        guidelines common to all our applications. Our code is always structured
        to be as modular and easy to maintain as possible. Additionally, we
        incorporate unit and integration testing whenever feasible.
      </p>
      <h3>Why we use it</h3>
      <p>
        <ul>
          <li>
            <strong>Easy</strong>: Simple to learn and use with its
            component-based structure.
          </li>
          <li>
            <strong>Flexible</strong>: Easily integrates with various libraries
            and frameworks.
          </li>
          <li>
            <strong>Modular</strong>: Encourages reusable components for better
            organization.
          </li>
          <li>
            <strong>Popular</strong>: Supported by a strong community with
            abundant resources.
          </li>
          <li>
            <strong>TypeScript</strong> Compatibility: Works well with
            TypeScript for enhanced type safety and code quality.
          </li>
        </ul>
      </p>
      <Alert severity="info">
        This website has been created with <strong>React 18</strong>.
      </Alert>
    </Trans>
  ),
};

export default REACT;

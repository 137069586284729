import { CSSPropertiesDict } from "./utils/types";

const indexStyle: CSSPropertiesDict = {
  body: {
    margin: "0",
    padding: "10px",
  },
};

const indexStyleMd: CSSPropertiesDict = {
  ...indexStyle,
};

const indexStyleLtMd: CSSPropertiesDict = {
  body: {
    ...indexStyle.body,
    padding: "20px 0",
  },
};

const style = { indexStyleMd, indexStyleLtMd };

export default style;

import { Trans } from "@lingui/macro";
import logo from "../../../assets/pictures/logos/technical/angular-logo.png";
import nodeLogo from "../../../assets/pictures/logos/technical/node-logo.png";
import typescriptLogo from "../../../assets/pictures/logos/technical/typescript-logo.png";
import { TechnologyCardProps } from "../technology-card/interfaces";

const ANGULAR: TechnologyCardProps = {
  title: "Angular",
  order: 8,
  logo,
  subTitle: "TypeScript Web Frontend Framework",
  detailLogos: [nodeLogo, typescriptLogo],
  latestUsedVersion: "17",
  url: "www.djangoproject.com",
  favorite: false,
  description: (
    <Trans>
      <p>
        <strong>Angular</strong> is a robust framework widely used for building
        web applications.
      </p>{" "}
      <h3>How we use it</h3>
      <p>
        We utilize Angular as an alternative to React. It is typically
        implemented in a Docker container for development and then migrated to
        an Nginx container for production.
        <br />
        We keep component code separate from logic code in services and prevent
        unnecessary re-rendering by managing onChange events. State managers
        like NgXs are used to centralize data sources.
      </p>
      <p>
        <h3>Why we use it</h3>
        <ul>
          <li>
            <strong>Complete</strong>: Comprehensive framework with a wide array
            of features.
          </li>
          <li>
            <strong>Second Popular Framework</strong>: Highly popular, ensuring
            good community support.
          </li>
          <li>
            <strong>Service Injections</strong>: Facilitates dependency
            injection for better code management.
          </li>
          <li>
            <strong>Strict Code Structure</strong>: Enforces a more structured
            code approach compared to React.
          </li>
        </ul>
      </p>
    </Trans>
  ),
};

export default ANGULAR;

import { Trans } from "@lingui/macro";
import { useMediaQuery, useTheme } from "@mui/material";
import { motion } from "motion/react";
import BaseContainer from "../../shared/components/base/container";
import LogoSeparator from "../../shared/components/logo-separator";
import MOTION_PROPS from "../../utils/motions";
import { DATA } from "./data";
import ServiceCard from "./service";
import style from "./style";

const ServicesPage = () => {
  const theme = useTheme();
  const isLtMd = useMediaQuery(theme.breakpoints.down("md"));

  const orderedData = DATA.sort(
    (needCard, nextNeedCard) => needCard.order - nextNeedCard.order,
  );

  return (
    <div className="services-page">
      <BaseContainer>
        <motion.h1
          initial={MOTION_PROPS.TITLE.INITIAL}
          viewport={MOTION_PROPS.TITLE.VIEWPORT}
          whileInView={MOTION_PROPS.TITLE.WHILE_IN_VIEW}
          style={isLtMd ? style.titleLtMd : style.titleMd}
        >
          <Trans>What you need</Trans>
        </motion.h1>

        {orderedData.map((cardProps, index) => {
          return (
            <div key={cardProps.order} className="service-card-container">
              <ServiceCard key={cardProps.order} cardProps={cardProps} />
              {index != orderedData.length - 1 && (
                <div style={style.separator}>
                  <LogoSeparator />
                </div>
              )}
            </div>
          );
        })}
      </BaseContainer>
    </div>
  );
};

export default ServicesPage;

import { Trans } from "@lingui/macro";
import { Alert } from "@mui/material";
import linuxLogo from "../../../assets/pictures/logos/technical/linux-logo.png";
import logo from "../../../assets/pictures/logos/technical/ubuntu-logo.png";
import { TechnologyCardProps } from "../technology-card/interfaces";

const UBUNTU: TechnologyCardProps = {
  title: "Ubuntu",
  order: 5,
  logo,
  subTitle: "Linux client and server operating system",
  detailLogos: [linuxLogo],
  latestUsedVersion: "24.04",
  url: "ubuntu.com",
  favorite: true,
  description: (
    <Trans>
      <p>
        <strong>Ubuntu</strong> is a popular Linux distribution renowned for its
        user-friendliness, stability, and robust community support.{" "}
      </p>
      <h3>How we use it</h3>
      <p>
        Almost all our servers run on Ubuntu. Linux is well-suited to web
        development and providing services.
        <br />
        Additionally, we typically containerize all services to ensure isolation
        from the host environment, though we could easily transition to native
        applications with Ubuntu if needed.
      </p>
      <p>
        <h3>Why we use it</h3>
        <ul>
          <li>
            <strong>Most Popular Distribution</strong>: Widely adopted with
            extensive community support.
          </li>
          <li>
            <strong>Regular Updates</strong>: Ensures security and performance
            with frequent updates.
          </li>
          <li>
            <strong>LTS Versions</strong>: Long-term support versions provide
            stability and reliability.
          </li>
          <li>
            <strong>Docker Friendly</strong>: Excellent compatibility with
            Docker, facilitating containerization.
          </li>
        </ul>
      </p>
      <Alert severity="info">
        This website is hosted on a server running Ubuntu.
      </Alert>
    </Trans>
  ),
};

export default UBUNTU;

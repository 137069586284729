import { ThemeContext } from "@emotion/react";
import { Trans } from "@lingui/macro";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WorkIcon from "@mui/icons-material/Work";
import { Button, Grid2 } from "@mui/material";
import { useContext, useState } from "react";
import darkLogo from "../../../../assets/pictures/logos/shared/dark/brand-logo.png";
import lightLogo from "../../../../assets/pictures/logos/shared/light/brand-logo.png";
import {
  CONTACT_MAIL,
  LINKED_IN_LINK,
  LINKED_IN_PSEUDO_LINK,
  VAT_NUMBER,
} from "../../../../utils/consts";
import { removeSpaces } from "../../../../utils/string";
import CopyToClipboardAlert from "../../../../views/home/copy-to-clipboard.alert";
import LocaleSelector from "../../locale-selector";
import ThemeSelector from "../../theme-selector";
import style from "./style";
import { VATNumberSpan } from "./styled-components";
import { Download } from "@mui/icons-material";
import DownloadCVButton from "./download-cv";

const Contact = () => {
  const { darkMode } = useContext<object>(ThemeContext) as {
    darkMode: boolean;
  };

  const [showAlert, setShowAlert] = useState<boolean>(false);

  const handleVATnumberClick = (): void => {
    navigator.clipboard.writeText(removeSpaces(VAT_NUMBER));
    setShowAlert(true);
  };

  return (
    <div className="HomePageContacts">
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 3 }}>
          <img
            src={darkMode ? darkLogo : lightLogo}
            alt="Northenware Logo"
            style={style.logo}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }}>
          <h2 style={style.title}>
            <Trans>Contact</Trans>
          </h2>
          <p style={style.details}>
            Northenware BV
            <Grid2 container spacing={1}>
              <Grid2 size={12}>
                <a href={"mailto:" + CONTACT_MAIL}>
                  <Button
                    variant="contained"
                    startIcon={<EmailIcon />}
                    style={style.button}
                  >
                    {CONTACT_MAIL}
                  </Button>
                </a>
              </Grid2>
              <Grid2 size={12}>
                <a href={LINKED_IN_LINK} target="_blank">
                  <Button
                    variant="outlined"
                    startIcon={<LinkedInIcon />}
                    style={style.button}
                  >
                    {LINKED_IN_PSEUDO_LINK}
                  </Button>
                </a>
              </Grid2>
              <Grid2 size={12}>
                <DownloadCVButton style={style.button} />
              </Grid2>
              <Grid2 size={12}>
                <Trans>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<WorkIcon />}
                    style={style.button}
                    onClick={handleVATnumberClick}
                  >
                    VAT number:&nbsp;
                    <VATNumberSpan>{VAT_NUMBER}</VATNumberSpan>
                  </Button>
                </Trans>
              </Grid2>
            </Grid2>
          </p>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }}>
          <h2 style={style.title}>
            <Trans>Settings</Trans>
          </h2>
          <p style={style.details}>
            <Grid2 container spacing={2}>
              <Grid2 size={12}>
                <LocaleSelector />
              </Grid2>
              <Grid2 size={12}>
                <ThemeSelector />
              </Grid2>
            </Grid2>
          </p>
        </Grid2>
        <Grid2 container spacing={{ xs: 2, md: 1 }}></Grid2>
      </Grid2>

      {/* Hidden components */}
      <CopyToClipboardAlert showAlert={showAlert} setShowAlert={setShowAlert} />
    </div>
  );
};

export default Contact;

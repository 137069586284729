import { Trans } from "@lingui/macro";
import illustration from "../../../assets/pictures/illustrations/services/ai-integration.webp";
import { CustomerNeedsCardProps } from "../../technical/technology-card/interfaces";

const AI_INTEGRATION: CustomerNeedsCardProps = {
  id: "06-ai-integration",
  comingSoon: true,
  illustration,
  illustrationAlt: "Get your AI integrated in your projects.",
  order: 6,
  needsList: (
    <ul>
      <li>
        <Trans>Integrate AI into your website or application.</Trans>
      </li>
      <li>
        <Trans>
          Improve your user experience with AI by providing personalized
          content.
        </Trans>
      </li>
      <li>
        <Trans>Automate complex tasks with AI to save time and money.</Trans>
      </li>
      <li>
        <Trans>
          Implement chatbots to provide instant customer support and increase
          your sales.
        </Trans>
      </li>
      <li>
        <Trans>
          Analyze data and make predictions to improve your business decisions.
        </Trans>
      </li>
      <li>
        <Trans>
          Use AI to detect fraud, prevent spam, and improve security on your
          website or application.
        </Trans>
      </li>
    </ul>
  ),
  servicesList: (
    <ul>
      <li>
        <Trans>
          Implement machine learning models to analyze data and make
          predictions.
        </Trans>
      </li>
      <li>
        <Trans>
          Develop chatbots to provide instant customer support and increase.
        </Trans>
      </li>
      <li>
        <Trans>
          Implement recommendation systems to provide personalized content.
        </Trans>
      </li>
      <li>
        <Trans>
          Develop computer vision models to analyze images and videos.
        </Trans>
      </li>
      <li>
        <Trans>
          Implement natural language processing models to analyze text and
          speech.
        </Trans>
      </li>
      <li>
        <Trans>
          Generate images, videos, and text with generative AI models.
        </Trans>
      </li>
    </ul>
  ),
};

export default AI_INTEGRATION;

import { CSSPropertiesDict } from "../../../utils/types";

const alert: React.CSSProperties = {
  marginLeft: "1em",
};

const style: CSSPropertiesDict = {
  alert,
};

export default style;

import { CSSPropertiesDict } from "../../../utils/types";

const container: React.CSSProperties = {
  width: "100%",
};

const style: CSSPropertiesDict = {
  container,
};

export default style;

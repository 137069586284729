import { PartnerCardProps } from "../../../../views/technical/technology-card/interfaces";
import logo from "../../../../assets/pictures/logos/home/partners/breast-international-group/breast-international-group.png";

const BREAST_INTERNATIONAL_GROUP: PartnerCardProps = {
  name: "Breast International Group",
  order: 0,
  logo,
  urls: {
    website: "https://www.bigagainstbreastcancer.org/",
    facebook: "https://www.facebook.com/BIGagainstbreastcancer",
    instagram: "https://www.instagram.com/bigagainstbc/",
    linkedIn: "https://www.linkedin.com/company/breast-international-group/",
    twitter: "https://x.com/BIGxResearch",
    youtube: "https://www.youtube.com/user/BreastIntGroup",
  },
};

export default BREAST_INTERNATIONAL_GROUP;

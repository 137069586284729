import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Grid2, IconButton, Stack, useTheme } from "@mui/material";
import React, { ReactElement } from "react";
import style from "../style";
import { PartnerContainerDiv } from "../styled-components";
import { PartnerCardProps } from "../../../../views/technical/technology-card/interfaces";

type PartnerLinkType =
  | "facebook"
  | "instagram"
  | "linkedin"
  | "twitter"
  | "website"
  | "youtube";

const PartnerLink: React.FC<{
  url: string;
  logo: ReactElement;
  type: PartnerLinkType;
  iconColor?: string;
}> = ({ url, logo, type, iconColor }) => {
  let color;

  switch (type) {
    case "facebook":
      color = "#1877F2";
      break;
    case "instagram":
      color = "#C13584";
      break;
    case "linkedin":
      color = "#0077B5";
      break;
    case "twitter":
      color = iconColor;
      break;
    case "website":
      color = iconColor;
      break;
    case "youtube":
      color = "#FF0000";
      break;
    default:
      color = iconColor;
      break;
  }

  return (
    <a href={url} target="_blank" rel="noreferrer">
      <IconButton style={{ color }}>{logo}</IconButton>
    </a>
  );
};

const PartnerCard: React.FC<{ partner: PartnerCardProps; index: number }> = ({
  partner,
  index,
}) => {
  const theme = useTheme();
  const color =
    index % 2 === 0 ? theme.palette.secondary.main : theme.palette.primary.main;

  return (
    <div className="Partner">
      <PartnerContainerDiv color={color}>
        <a
          href={partner.urls.website}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Stack
            direction="column"
            alignContent="center"
            justifyContent="center"
            style={style.logoContainer}
          >
            <img src={partner.logo} alt={partner.name} style={style.logo} />
          </Stack>
        </a>
        <div style={style.name}>{partner.name}</div>
        <Grid2 direction="row" justifyContent="center" spacing={0}>
          <PartnerLink
            url={partner.urls.website}
            logo={<LanguageIcon />}
            iconColor={color}
            type="website"
          />

          {partner.urls?.twitter && (
            <PartnerLink
              url={partner.urls.twitter}
              logo={<XIcon />}
              iconColor={theme.palette.text.primary}
              type="twitter"
            />
          )}

          {partner.urls?.linkedIn && (
            <PartnerLink
              url={partner.urls.linkedIn}
              logo={<LinkedInIcon />}
              type="linkedin"
            />
          )}

          {partner.urls?.instagram && (
            <PartnerLink
              url={partner.urls.instagram}
              logo={<InstagramIcon />}
              type="instagram"
            />
          )}

          {partner.urls?.facebook && (
            <PartnerLink
              url={partner.urls.facebook}
              logo={<FacebookIcon />}
              type="facebook"
            />
          )}

          {partner.urls?.youtube && (
            <PartnerLink
              url={partner.urls.youtube}
              logo={<YouTubeIcon />}
              type="youtube"
            />
          )}
        </Grid2>
      </PartnerContainerDiv>
    </div>
  );
};

export default PartnerCard;

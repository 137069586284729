import { createContext } from "react";
import { DEFAULT_LOCALE } from "../../utils/consts";

/** The context type for the locale context. */
interface LocaleContextType {
  locale: string;
  setLocale: React.Dispatch<React.SetStateAction<string>>;
}

/** The context that provides the current locale and a function to update it. */
const LocaleContext = createContext<LocaleContextType>({
  locale: DEFAULT_LOCALE,
  setLocale: () => {},
});

export default LocaleContext;

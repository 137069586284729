import { styled, Theme } from "@mui/material";
import fonts from "../../../../utils/fonts";

export const VATNumberSpan: React.FC<React.HTMLAttributes<HTMLSpanElement>> =
  styled("span")(({ theme }: { theme: Theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontFamily: fonts.monospace,
    fontWeight: "bold",
    marginLeft: "2px",
    padding: "0 5px",
  }));

import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import BaseComponent from "../shared/components/base";
import HomePage from "../views/home";
import ServicesPage from "../views/services";
import DetailPage from "../views/technical";
import { DynamicDict } from "./types";

export const ROUTES: DynamicDict = {
  HOME: "",
  SERVICES: "services",
  TECHNICAL: "technical",
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<BaseComponent />}>
      <Route path={ROUTES.HOME} element={<HomePage />} />
      <Route path={ROUTES.SERVICES} element={<ServicesPage />} />
      <Route path={ROUTES.TECHNICAL} element={<DetailPage />} />
      <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
    </Route>,
  ),
);

export default router;

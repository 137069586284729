import { t, Trans } from "@lingui/macro";
import illustration from "../../../assets/pictures/illustrations/services/internal-webtool.webp";
import { CustomerNeedsCardProps } from "../../technical/technology-card/interfaces";

const INTERNAL_WEBTOOL: CustomerNeedsCardProps = {
  id: "03-internal-webtool",
  comingSoon: false,
  illustration,
  illustrationAlt: t`Get your internal webtool to make your daily work easier and automated.`,
  order: 3,
  needsList: (
    <ul>
      <li>
        <Trans>
          An internal tool (not client facing) to automate repetitive tasks and
          make your daily work.
        </Trans>
      </li>
      <li>
        <Trans>
          Reduce the amount of errors made by doing repetitive tasks over and
          over.
        </Trans>
      </li>
      <li>
        <Trans>
          Make team collaboration easier by sharing information and tools.
        </Trans>
      </li>
      <li>
        <Trans>
          You need to access your tools and information from anywhere.
        </Trans>
      </li>
      <li>
        <Trans>
          You need to keep track of your work and your team, being able to undo
          actions and manage permissions among your team.
        </Trans>
      </li>
    </ul>
  ),
  servicesList: (
    <ul>
      <li>
        <Trans>
          An internal webtool that can be accessed from anywhere with an
          internet connection.
        </Trans>
      </li>
      <li>
        <Trans>
          A user-friendly interface that makes it easy to navigate and use the
          application.
        </Trans>
      </li>
      <li>
        <Trans>
          A secure way to store your data and your team's data, with a backup
          system.
        </Trans>
      </li>
      <li>
        <Trans>
          A simple way to login and manage your team's permissions, with
          possibility to integrate external authentication providers such as
          Google or Microsoft.
        </Trans>
      </li>
      <li>
        <Trans>
          The ability to run long-runnnig tasks in the background, like
          generating reports or sending emails and being notified when they are
          done.
        </Trans>
      </li>
    </ul>
  ),
};

export default INTERNAL_WEBTOOL;

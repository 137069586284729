import { Trans } from "@lingui/macro";
import { Alert } from "@mui/material";
import logo from "../../../assets/pictures/logos/technical/nginx-logo.png";
import { TechnologyCardProps } from "../technology-card/interfaces";

const NGINX: TechnologyCardProps = {
  title: "NGINX",
  order: 5,
  logo,
  subTitle: "High-performance web server and reverse proxy",
  detailLogos: [],
  latestUsedVersion: "3.20",
  url: "nginx.org",
  favorite: true,
  description: (
    <Trans>
      <p>
        <strong>Nginx</strong> is a high-performance web server and reverse
        proxy server known for its stability, rich feature set, and low resource
        consumption.
      </p>
      <h3>How we use it</h3>
      <p>
        When using frontend applications such as React and Angular, the code is
        compiled and built for production before being injected into an Nginx
        Docker container ready to serve the compiled files. We usually add a
        Certbot service to secure the domain name with SSL certificates and set
        the service to restart automatically every 6 hours to refresh the
        certificates if needed.
        <br />
        All traffic from HTTP is redirected to HTTPS, and we can also use an
        additional Nginx service as a proxy.
      </p>
      <p>
        <h3>Why we use it</h3>
        <ul>
          <li>
            <strong>Easy to Implement and Configure</strong>: Simplifies the
            setup process.
          </li>
          <li>
            <strong>Easy to Secure with SSL Certificates and Certbot</strong>:
            Enhances security with automated SSL certificate management.
          </li>
          <li>
            <strong>Lightweight</strong>: Requires minimal resources, ensuring
            efficient performance.
          </li>
          <li>
            <strong>Can Be Used as Proxy</strong>: Versatile in handling various
            proxy server tasks.
          </li>
        </ul>
      </p>
      <Alert severity="info">
        This website is served by a Nginx server running in a Docker container.
      </Alert>
    </Trans>
  ),
};

export default NGINX;

import { TechnologyCardProps } from "../technology-card/interfaces";
import DJANGO from "./00-django";
import REACT from "./01-react";
import DOCKER from "./02-docker";
import POSTGRESQL from "./03-postgres";
import GRAPHQL from "./04-graphql";
import NGINX from "./05-nginx";
import UBUNTU from "./05-ubuntu";
import GITLAB_PIPELINES from "./06-gitlab-pipeline";
import FASTAPI from "./07-fastapi";
import ANGULAR from "./08-angular";
import CELERY from "./09-celery";
import PYTHON from "./10-python";
import MYSQL from "./11-mysql";
import BITBUCKET_PIPELINES from "./12-bitbucket-pipeline";

export const DATA: TechnologyCardProps[] = [
  DJANGO,
  REACT,
  DOCKER,
  POSTGRESQL,
  GRAPHQL,
  NGINX,
  UBUNTU,
  GITLAB_PIPELINES,
  FASTAPI,
  ANGULAR,
  CELERY,
  PYTHON,
  MYSQL,
  BITBUCKET_PIPELINES,
];

import { Trans } from "@lingui/macro";
import logo from "../../../assets/pictures/logos/technical/python-logo.png";
import { TechnologyCardProps } from "../technology-card/interfaces";

const PYTHON: TechnologyCardProps = {
  title: "Python scripting",
  order: 10,
  logo,
  subTitle: "High level programming language",
  detailLogos: [],
  latestUsedVersion: "3.12",
  url: "python.org",
  favorite: false,
  description: (
    <Trans>
      <p>
        <strong>Python Scripting</strong> is an efficient way to automate tasks
        and perform complex operations with simplicity and precision.
      </p>
      <h3>How we use it</h3>
      <p>
        We utilize Python scripting when we need to extract common code from
        multiple applications into a shared library or when running standalone
        scripts is more practical than building a full application. These
        scripts can be integrated with all our Python backends.
        <br />
        They are also handy for performing complex operations that need to be
        executed only once.
      </p>
      <h3>Why we use it</h3>
      <p>
        <ul>
          <li>
            <strong>Popular</strong>: Widely used and supported in the developer
            community.
          </li>
          <li>
            <strong>Powerful</strong>: Capable of handling complex tasks
            efficiently.
          </li>
          <li>
            <strong>Easy Integration</strong>: Seamlessly integrates with our
            Python backend systems.
          </li>
          <li>
            <strong>Linux Friendly</strong>: Excellent compatibility with Linux
            environments.
          </li>
        </ul>
      </p>
    </Trans>
  ),
};

export default PYTHON;

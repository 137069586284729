import fonts from "../../../utils/fonts";
import { CSSPropertiesDict } from "../../../utils/types";

const logo: React.CSSProperties = {
  width: "100%",
};

const logoContainer: React.CSSProperties = {
  aspectRatio: "4/3",
  padding: "1em",
};

const name: React.CSSProperties = {
  fontFamily: fonts.default,
};

const title: React.CSSProperties = {
  fontFamily: fonts.title,
  textTransform: "uppercase",
};

const style: CSSPropertiesDict = {
  logo,
  logoContainer,
  name,
  title,
};

export default style;

import { on } from "events";
import { DEFAULT_CV_FILE_PATH } from "../../../../utils/consts";
import { Trans } from "@lingui/macro";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

interface DownloadCVButtonProps {
  filePath?: string;
  style?: React.CSSProperties;
}

const DownloadCVButton: React.FC<DownloadCVButtonProps> = ({
  filePath = DEFAULT_CV_FILE_PATH,
  style = {},
}) => {
  /** Function to download PDF file using anchor tag. */
  const onButtonClick = async () => {
    try {
      const response = await fetch(filePath);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.style.display = "none";
      a.href = url;
      a.download = "Northenware - Bryan Ramsamy CV.pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the CV:", error);
    }
  };

  return (
    <Trans>
      <Button
        color="secondary"
        variant="contained"
        startIcon={<DownloadIcon />}
        style={style}
        onClick={onButtonClick}
      >
        Download CV
      </Button>
    </Trans>
  );
};

export default DownloadCVButton;

import { CSSPropertiesDict } from "../../../utils/types";

const logo: React.CSSProperties = {
  margin: "3px 0",
  width: "70px",
};

const style: CSSPropertiesDict = {
  logo,
};

export default style;

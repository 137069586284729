import { Trans } from "@lingui/macro";
import { Alert } from "@mui/material";
import logo from "../../../assets/pictures/logos/technical/graphql-logo.png";
import { TechnologyCardProps } from "../technology-card/interfaces";

const GRAPHQL: TechnologyCardProps = {
  title: "GraphQL",
  order: 4,
  logo,
  subTitle: "API data query and manipulation language",
  detailLogos: [],
  latestUsedVersion: "16.7",
  url: "graphql.org",
  favorite: true,
  description: (
    <Trans>
      <p>
        <strong>GraphQL</strong> is a query language for APIs that provides a
        more efficient, powerful, and flexible alternative to REST.
      </p>
      <h3>How we use it</h3>
      <p>
        We implement GraphQL using Graphene or Strawberry on the Python backend
        and Apollo Client on our frontends (React and Angular).
        <br />
        It is primarily used for private APIs, as it simplifies the process of
        extending the application. The frontend defines its needed DTO (Data
        Transfer Object), ensuring that this logic remains separate from the
        backend.
      </p>
      <h3>Why we use it</h3>
      <p>
        <ul>
          <li>
            <strong>Easy to Extend</strong>: Facilitates application scalability
            and adaptability.
          </li>
          <li>
            <strong>Frontend Handles DTO</strong>: Keeps data structure logic on
            the frontend, reducing backend complexity.
          </li>
          <li>
            <strong>Advanced Queries</strong>: Allows for more sophisticated
            querying capabilities.
          </li>
        </ul>
      </p>
      <Alert severity="info">
        No backend has been implemented on this website so far. If this should
        happen in the future, we would likely use GraphQL as the API query
        language.
      </Alert>
    </Trans>
  ),
};

export default GRAPHQL;

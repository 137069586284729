import fonts from "../../utils/fonts";
import { CSSPropertiesDict } from "../../utils/types";

const separator: React.CSSProperties = {
  textAlign: "center",
};

const title: React.CSSProperties = {
  fontFamily: fonts.title,
  textTransform: "uppercase",
};

const titleLtMd: React.CSSProperties = {
  ...title,
  textAlign: "center",
};

const titleMd: React.CSSProperties = {
  ...title,
  textAlign: "left",
};

const style: CSSPropertiesDict = {
  separator,
  titleLtMd,
  titleMd,
};

export default style;

import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { Grid2 } from "@mui/material";
import { motion } from "motion/react";
import { ReactTyped } from "react-typed";
import MOTION_PROPS from "../../../utils/motions";
import { CustomerNeedsCardProps } from "../../technical/technology-card/interfaces";
import ComingSoonOverlay from "./coming-soon-overlay";
import style from "./style";

const ServiceCard: React.FC<{ cardProps: CustomerNeedsCardProps }> = ({
  cardProps,
}) => {
  // WORKAROUND: The t function does not work from the DATA files
  // FIXME: Should not be like this. Title should be defined in DATA files, not
  // here.
  let cardTitleToTranslate: string = "";

  switch (cardProps.id) {
    case "00-basic-website":
      cardTitleToTranslate = t`I want a simple website.`;
      break;
    case "01-advanced-website":
      cardTitleToTranslate = t`I want an advanced interactive website or web application.`;
      break;
    case "02-mobile-application":
      cardTitleToTranslate = t`I want a mobile application.`;
      break;
    case "03-internal-webtool":
      cardTitleToTranslate = t`I want to create an internal tool to make my daily work easier and automated.`;
      break;
    case "04-software-development":
      cardTitleToTranslate = t`I want to hire a full-stack software developer.`;
      break;
    case "05-teaching":
      cardTitleToTranslate = t`I want to coach my team or class how to code.`;
      break;
    case "06-ai-integration":
      cardTitleToTranslate = t`I want to integrate AI in my project.`;
      break;
    case "07-cloud-integration":
      cardTitleToTranslate = t`I want to integrate cloud services in my project.`;
      break;
  }

  let translatedCardTitle = i18n._(cardTitleToTranslate);

  return (
    <div className="service-card" id={cardProps.id}>
      <ReactTyped
        startWhenVisible
        strings={[translatedCardTitle]}
        style={style.topic}
        typeSpeed={40}
      />
      <br />

      <motion.div
        initial={MOTION_PROPS.CONTAINER.INITIAL}
        viewport={MOTION_PROPS.CONTAINER.VIEWPORT}
        whileInView={MOTION_PROPS.CONTAINER.WHILE_IN_VIEW}
      >
        <ComingSoonOverlay active={cardProps.comingSoon}>
          <Grid2 container spacing={0} style={style.container}>
            <Grid2 size={{ xs: 12, md: 4 }} style={style.detailsContainer}>
              <motion.div
                style={style.needsDiv}
                whileHover={MOTION_PROPS.CONTAINER.WHILE_HOVER}
              >
                <h2 style={style.subTitle}>
                  <Trans>Your needs</Trans>
                </h2>
                <hr />

                <div style={style.listContainer}>{cardProps.needsList}</div>
              </motion.div>
            </Grid2>

            <Grid2 size={{ xs: 12, md: 4 }}>
              <div style={style.imageContainer}>
                <img
                  src={cardProps.illustration}
                  alt={cardProps.illustrationAlt}
                  style={style.image}
                />
              </div>
            </Grid2>

            <Grid2 size={{ xs: 12, md: 4 }} style={style.detailsContainer}>
              <motion.div
                style={style.needsDiv}
                whileHover={MOTION_PROPS.CONTAINER.WHILE_HOVER}
              >
                <h2 style={style.subTitle}>
                  <Trans>We provide</Trans>
                </h2>
                <hr />

                <div style={style.listContainer}>{cardProps.servicesList}</div>
              </motion.div>
            </Grid2>
          </Grid2>
        </ComingSoonOverlay>
      </motion.div>
    </div>
  );
};

export default ServiceCard;

import { createTheme } from "@mui/material";
import baseTheme from "./base";
import lightPalette from "./palettes/light";
import { NORD_COLORS } from "./palettes/colors";

const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    primary: lightPalette.primary,
    mode: "light",
    text: lightPalette.text,
    background: lightPalette.background,
  },
  components: {
    ...baseTheme.components,
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&": {
            color: lightPalette.text.primary,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected:hover": {
            backgroundColor: lightPalette.primary.main,
            color: lightPalette.primary.contrastText,
          },
          "&:hover": {
            backgroundColor: lightPalette.primary.main,
            color: lightPalette.primary.contrastText,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          color: lightPalette.primary.main,
          textDecoration: "none",
          "&:hover": {
            color: NORD_COLORS.NORD14,
          },
          "&:visited": {
            color: NORD_COLORS.NORD15,
            "&:hover": {
              color: NORD_COLORS.NORD14,
            },
          },
          "&:focus": {
            color: NORD_COLORS.NORD14,
            outline: `1px solid ${NORD_COLORS.NORD14}`,
            textDecoration: "underline",
          },
          "&:active": {
            color: NORD_COLORS.NORD12,
          },
        },
      },
    },
  },
});

export default lightTheme;

import { Trans } from "@lingui/macro";
import { Alert } from "@mui/material";
import logo from "../../../assets/pictures/logos/technical/django-logo.png";
import graphqlLogo from "../../../assets/pictures/logos/technical/graphql-logo.png";
import gunicornLogo from "../../../assets/pictures/logos/technical/gunicorn-logo.png";
import pythonLogo from "../../../assets/pictures/logos/technical/python-logo.png";
import { TechnologyCardProps } from "../technology-card/interfaces";

const DJANGO: TechnologyCardProps = {
  title: "Django",
  order: 0,
  logo,
  subTitle: "Python Web framework",
  detailLogos: [pythonLogo, gunicornLogo, graphqlLogo],
  latestUsedVersion: "4.1",
  url: "www.djangoproject.com",
  favorite: true,
  description: (
    <Trans>
      <p>
        <strong>Django</strong> is a versatile backend framework known for its
        robustness and efficiency.
      </p>
      <h3>How we use it</h3>
      <p>
        In our Full-Stack applications, we typically use Django as a backend to
        compute data and interact with a PostgreSQL database. We usually
        leverage Graphene for our API technology, though REST API can also be
        utilized.
        <br />
        Django's DRY (Don't Repeat Yourself) methodology allows us to keep our
        code as modular and maintainable as our frontend code. Following strict
        internal guidelines ensures code consistency and cleanliness.
        Additionally, when not handled by an external service, Django manages
        the JWT authentication for the frontend.
      </p>
      <h3>Why we use it</h3>
      <p>
        <ul>
          <li>
            <strong>Battery-included</strong>: Comprehensive and complete with
            all the essentials built-in.
          </li>
          <li>
            <strong>Powerful ORM</strong>: Facilitates efficient database
            interactions.
          </li>
          <li>
            <strong>Extensible</strong>: Numerous useful libraries can be
            plugged in.
          </li>
          <li>
            <strong>Popular</strong>: Widely used, making it suitable for large
            applications with robust community support.
          </li>
        </ul>
      </p>
      <Alert severity="info">
        This website currently doesn't make use of a backend. If this should
        change in the future, we would likely use Django.
      </Alert>
    </Trans>
  ),
};

export default DJANGO;

import { t, Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import illustration from "../../../assets/pictures/illustrations/services/software-developer.webp";
import { CustomerNeedsCardProps } from "../../technical/technology-card/interfaces";

const SOFTWARE_DEVELOPER: CustomerNeedsCardProps = {
  id: "04-software-development",
  comingSoon: false,
  illustration,
  illustrationAlt: t`Get your software developer.`,
  order: 4,
  needsList: (
    <ul>
      <li>
        <Trans>
          You already have identified your needs and want to add a software
          developer in your team to help you build your project.
        </Trans>
      </li>
      <li>
        <Trans>
          Collaborate with a full-stack developer capable of managing both the
          front-end and back-end of your application.
        </Trans>
      </li>
      <li>
        <Trans>
          A developer who can integrate seamlessly with your team and comprehend
          your project's objectives and limitations.
        </Trans>
      </li>
      <li>
        <Trans>
          A developer who is proficient in working with your current codebase
          and technologies.
        </Trans>
      </li>
      <li>
        <Trans>
          A developer who can collaborate with your team and contribute to their
          professional growth.
        </Trans>
      </li>
      <li>
        <Trans>High standards of code quality and documentation.</Trans>
      </li>
    </ul>
  ),
  servicesList: (
    <ul>
      <li>
        <Trans>
          Every technologies we use can be found on our{" "}
          <Link to={{ pathname: "/technical" }}>technical page</Link>.
          {/* FIXME: Using ROUTES does not work */}
        </Trans>
      </li>
    </ul>
  ),
};

export default SOFTWARE_DEVELOPER;

import { SnackbarOrigin } from "@mui/material";

export const CONTACT_MAIL = "contact@northenware.com";

export const DEFAULT_CV_FILE_PATH = "CV.pdf";

export const DEFAULT_LOCALE = "en";

export const DEFAULT_TEXTFIELD_WIDTH = "250px";

export const DEFAULT_ALERT_ANCHOR_ORIGIN: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

/** Time (in milliseconds) until an alert vanished after popping up */
export const DEFAULT_ALERT_HIDE_DURATION = 4000;

export const LINKED_IN_LINK = "https://www.linkedin.com/company/northenware/";

export const LINKED_IN_PSEUDO_LINK = "linkedIn.com/Northenware";

export const LOCAL_STORAGE_KEYS = {
  LOCALE: "locale",
  THEME: "theme",
};

export const SUPPORTED_LOCALES = {
  en: "English",
  fr: "Français",
  nl: "Nederlands",
};

export const VAT_NUMBER = "BE 1014 226 357";

import fonts from "../../utils/fonts";
import { CSSPropertiesDict } from "../../utils/types";

const button: React.CSSProperties = {
  width: "100%",
};

const container: React.CSSProperties = {
  textAlign: "center",
};

const containerLtMd: React.CSSProperties = {
  ...container,
  padding: "0em",
  paddingBottom: "1em",
};

const contactContainer: React.CSSProperties = {
  padding: "1em 0",
};

const contactPhrase: React.CSSProperties = {
  fontFamily: fonts.default,
  fontWeight: "bold",
  fontSize: "1.4em",
  textAlign: "start",
};

const containerMd: React.CSSProperties = {
  ...container,
};

const description: React.CSSProperties = {
  fontFamily: fonts.default,
  fontSize: "1.3em",
  padding: "0.5em 1em 0",
  textAlign: "justify",
};

const descriptionGridItem: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const illustration: React.CSSProperties = {
  aspectRatio: "30/20",
  objectFit: "cover",
  width: "100%",
};

const keyword: React.CSSProperties = {
  fontFamily: fonts.default,
  fontSize: "1.1em",
  fontWeight: "bold",
};

const paper: React.CSSProperties = {
  paddingBottom: "1em",
};

const subTitle: React.CSSProperties = {
  fontFamily: fonts.title,
  fontSize: "1.1em",
  textAlign: "center",
  textTransform: "uppercase",
};

const title: React.CSSProperties = {
  fontFamily: fonts.title,
  textTransform: "uppercase",
};

const titleLtMd: React.CSSProperties = {
  ...title,
  textAlign: "center",
};

const titleMd: React.CSSProperties = {
  ...title,
  textAlign: "left",
};

const style: CSSPropertiesDict = {
  button,
  contactContainer,
  contactPhrase,
  containerMd,
  containerLtMd,
  description,
  descriptionGridItem,
  illustration,
  keyword,
  paper,
  subTitle,
  titleLtMd,
  titleMd,
};

export default style;

import { CSSPropertiesDict } from "../../../../utils/types";

const brandLogo: React.CSSProperties = {
  height: "40px",
};

const navigationContainer: React.CSSProperties = {
  margin: "10px 0",
  padding: "5px",
};

const style: CSSPropertiesDict = {
  brandLogo,
  navigationContainer,
};

export default style;

import { ThemeContext, ThemeProvider } from "@emotion/react";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { CssBaseline } from "@mui/material";
import { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import "./fonts.css";
import LocaleContext from "./shared/contexts/locale";
import { isPreferredThemeDark } from "./themes/base";
import darkTheme from "./themes/dark";
import lightTheme from "./themes/light";
import { DEFAULT_LOCALE } from "./utils/consts";
import { dynamicActivate, getPreferredLocale } from "./utils/i18n";
import router from "./utils/routes";

const App = () => {
  /**  Dynamically activates the preferred locale on mount. */
  useEffect(() => {
    (async () => {
      await dynamicActivate(getPreferredLocale(DEFAULT_LOCALE), setLocale);
    })();
  }, []);

  const [locale, setLocale] = useState<string>(DEFAULT_LOCALE);
  const [darkMode, setDarkMode] = useState<boolean>(isPreferredThemeDark());

  return (
    <I18nProvider i18n={i18n}>
      <LocaleContext.Provider value={{ locale, setLocale }}>
        <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
          <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            <div className="northenware-app">
              <CssBaseline enableColorScheme />
              <RouterProvider router={router} />
            </div>
          </ThemeProvider>
        </ThemeContext.Provider>
      </LocaleContext.Provider>
    </I18nProvider>
  );
};

export default App;

import { ThemeContext } from "@emotion/react";
import { Trans } from "@lingui/macro";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import React, { useContext } from "react";
import { LOCAL_STORAGE_KEYS } from "../../../utils/consts";
import style from "./style";

const ThemeSelector = () => {
  const { darkMode, setDarkMode } = useContext<any>(ThemeContext);

  const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const darkMode = event.target.value === "dark";

    setDarkMode(darkMode);
    localStorage.setItem(LOCAL_STORAGE_KEYS.THEME, darkMode ? "dark" : "light");
  };

  return (
    <div className="ThemeSelector" style={style.container}>
      <TextField
        select
        label={<Trans>Select your preferred theme</Trans>}
        value={darkMode ? "dark" : "light"}
        fullWidth={true}
        onChange={handleThemeChange}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <ColorLensIcon />
              </InputAdornment>
            ),
          },
        }}
      >
        <MenuItem value="light">
          <Trans>Day mode</Trans>
        </MenuItem>

        <MenuItem value="dark">
          <Trans>Night mode</Trans>
        </MenuItem>
      </TextField>
    </div>
  );
};

export default ThemeSelector;

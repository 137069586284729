import { styled } from "@mui/material";

interface PartnerContainerDivProps
  extends React.HTMLAttributes<HTMLDivElement> {
  color?: string;
}

export const PartnerContainerDiv: React.FC<PartnerContainerDivProps> = styled(
  "div",
)<PartnerContainerDivProps>(({ theme, color }) => ({
  backgroundColor: theme.palette.background.default,
  borderColor: color,
  borderRadius: "5px",
  borderStyle: "solid",
  borderWidth: "1px",
}));

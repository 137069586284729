import { Trans } from "@lingui/macro";
import { motion } from "motion/react";
import { ReactNode } from "react";
import MOTION_PROPS from "../../../utils/motions";
import style from "./style";

const ComingSoonOverlay: React.FC<{ active: boolean; children: ReactNode }> = ({
  active,
  children,
}) => {
  if (!active) {
    return <div className="coming-soon-overlay">{children}</div>;
  }

  return (
    <motion.div
      whileHover={MOTION_PROPS.CONTAINER.WHILE_HOVER}
      className="coming-soon-overlay"
      style={style.backdropContainer}
    >
      <div style={style.backdropDiv}>
        <h2 style={style.backdropText}>
          <Trans>Coming soon</Trans>
        </h2>
      </div>
      <div style={style.backdropChildren}>{children}</div>
    </motion.div>
  );
};

export default ComingSoonOverlay;

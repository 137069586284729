import { t, Trans } from "@lingui/macro";
import illustration from "../../../assets/pictures/illustrations/services/mobile-application.webp";
import { CustomerNeedsCardProps } from "../../technical/technology-card/interfaces";
import ADVANCED_WEBSITE from "./01-advanced-website";

const MOBILE_APPLICATION: CustomerNeedsCardProps = {
  id: "02-mobile-application",
  comingSoon: false,
  illustration,
  illustrationAlt: t`Get your mobile application.`,
  order: 2,
  needsList: (
    <ul>
      <li>
        <Trans>
          Everything from{" "}
          <a href={`#${ADVANCED_WEBSITE.id}`}>an advanced application</a> the
          user can install on one's smartphone.
        </Trans>
      </li>
      <li>
        <Trans>
          A way to interact with your customers and provide them with a better
          experience.
        </Trans>
      </li>
      <li>
        <Trans>
          The ability to send notifications to your customers to keep them
          informed in real time.
        </Trans>
      </li>
      <li>
        <Trans>
          Make us of the phone's features like the camera, GPS, or sensors.
        </Trans>
      </li>
    </ul>
  ),
  servicesList: (
    <ul>
      <li>
        <Trans>
          A mobile application that can be installed on Android and iOS devices.
        </Trans>
      </li>
      <li>
        <Trans>
          A user-friendly interface that makes it easy to navigate and use the
          application.
        </Trans>
      </li>
      <li>
        <Trans>A secure way to store your data and your customers.</Trans>
      </li>
      <li>
        <Trans>
          A design that matches your brand identity and your website with
          animations, transitions, sounds effects and a dark mode.
        </Trans>
      </li>
      <li>
        <Trans>
          A way to track your users and see how they use your application, with
          their consent.
        </Trans>
      </li>
      <li>
        <Trans>
          The ability to update your application regularly to fix bugs and add
          new features.
        </Trans>
      </li>
    </ul>
  ),
};

export default MOBILE_APPLICATION;

import { NORD_COLORS } from "../../../themes/palettes/colors";
import fonts from "../../../utils/fonts";
import { CSSPropertiesDict } from "../../../utils/types";

const brand: React.CSSProperties = {
  width: "75%",
};

const brandLtMd: React.CSSProperties = {
  width: "100%",
};

const brandMd: React.CSSProperties = { ...brand };

const copyright: React.CSSProperties = {
  alignItems: "center",
  fontFamily: fonts.default,
  justifyContent: "center",
};

// TODO: Move to component level
const headerContainer: React.CSSProperties = {
  padding: "2em 0",
  textAlign: "center",
};

const headerContainerLtMd: React.CSSProperties = {
  ...headerContainer,
  padding: "0 0 1em",
};

const headerContainerMd: React.CSSProperties = {
  ...headerContainer,
};

const footerContainer: React.CSSProperties = {
  textAlign: "center",
};

const footerContainerLtMd: React.CSSProperties = { ...footerContainer };

const footerContainerMd: React.CSSProperties = {
  ...footerContainer,
  padding: "1em 0 0 0",
};

const version: React.CSSProperties = {
  color: NORD_COLORS.NORD15,
  fontFamily: fonts.monospace,
};

const style: CSSPropertiesDict = {
  brandLtMd,
  brandMd,
  copyright,
  headerContainerLtMd,
  headerContainerMd,
  footerContainerLtMd,
  footerContainerMd,
  version,
};

export default style;

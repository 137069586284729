import { Trans } from "@lingui/macro";
import logo from "../../../assets/pictures/logos/technical/fastapi-logo.png";
import gunicornLogo from "../../../assets/pictures/logos/technical/gunicorn-logo.png";
import openapiLogo from "../../../assets/pictures/logos/technical/openapi-logo.png";
import pythonLogo from "../../../assets/pictures/logos/technical/python-logo.png";
import { TechnologyCardProps } from "../technology-card/interfaces";

const FASTAPI: TechnologyCardProps = {
  title: "FastAPI",
  order: 7,
  logo,
  subTitle: "Python Web framework",
  detailLogos: [pythonLogo, openapiLogo, gunicornLogo],
  latestUsedVersion: "0.110",
  url: "fastapi.tiangolo.com",
  favorite: false,
  description: (
    <Trans>
      <p>
        <strong>FastAPI</strong> is a modern, fast (high-performance) web
        framework for building APIs with Python.
      </p>
      <h3>How we use it</h3>
      <p>
        We typically use FastAPI as an enhanced version of Flask, primarily to
        manage microservices. Additionally, when we need to expose endpoints to
        the public, the integrated OpenAPI and Swagger make it easier for API
        consumers to interact with our services.
      </p>
      <h3>Why we use it</h3>
      <p>
        <ul>
          <li>
            <strong>Fast</strong>: High performance, on par with Node.js and Go.
          </li>
          <li>
            <strong>Reliable</strong>: Built-in support for data validation and
            serialization.
          </li>
          <li>
            <strong>Suited for Small Applications and Microservices</strong>:
            Ideal for building lightweight and modular services.
          </li>
          <li>
            <strong>Swagger and OpenAPI Integrated</strong>: Simplifies API
            documentation and interaction for consumers.
          </li>
        </ul>
      </p>
    </Trans>
  ),
};

export default FASTAPI;

/** Motion properties for Framer Motion components. */
const DEFAULT_VALUES = {
  DURATION: 1,
};

/** Default variants for Framer Motion components. */
const DEFAULT_VARIANTS = {
  INITIAL: { opacity: 0 },
  VIEWPORT: { once: true },
  WHILE_IN_VIEW: {
    opacity: 1,
    transition: { duration: DEFAULT_VALUES.DURATION },
    y: 0,
  },
};

const CONTAINER = {
  ...DEFAULT_VARIANTS,
  INITIAL: { ...DEFAULT_VARIANTS.INITIAL, y: 50 },
  WHILE_HOVER: { scale: 1.1 },
};

/** Variants for the title component. */
const TITLE = {
  ...DEFAULT_VARIANTS,
  INITIAL: { ...DEFAULT_VARIANTS.INITIAL, y: -50 },
};

/** Motion properties for Framer Motion components. */
const MOTION_PROPS = {
  CONTAINER,
  TITLE,
};

export default MOTION_PROPS;

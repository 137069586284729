import { PartnerCardProps } from "../../../../views/technical/technology-card/interfaces";
import BREAST_INTERNATIONAL_GROUP from "./00-breast-international-group";
import MASRO_SOLUTIONS from "./01-masro-solutions";
import GLOBAL_SECURITY_EUROPE from "./03-global-security-europe";

export const DATA: PartnerCardProps[] = [
  BREAST_INTERNATIONAL_GROUP,
  MASRO_SOLUTIONS,
  GLOBAL_SECURITY_EUROPE,
];

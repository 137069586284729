import { CustomerNeedsCardProps } from "../../technical/technology-card/interfaces";
import BASIC_WEBSITE from "./00-basic-website";
import ADVANCED_WEBSITE from "./01-advanced-website";
import MOBILE_APPLICATION from "./02-mobile-application";
import INTERNAL_WEBTOOL from "./03-internal-tool";
import SOFTWARE_DEVELOPER from "./04-software-development";
import TEACHING from "./05-teaching";
import AI_INTEGRATION from "./06-ai-integration";
import CLOUD_INTEGRATION from "./07-cloud-integration";

export const DATA: CustomerNeedsCardProps[] = [
  BASIC_WEBSITE,
  ADVANCED_WEBSITE,
  MOBILE_APPLICATION,
  INTERNAL_WEBTOOL,
  SOFTWARE_DEVELOPER,
  TEACHING,
  AI_INTEGRATION,
  CLOUD_INTEGRATION,
];

import "../fonts.css";
import { DynamicDict } from "./types";

const fonts: DynamicDict = {
  default: "Northenware",
  title: "Northenware Title",
  monospace: "Roboto Mono Regular",
  sherif: "Domine",
};

export default fonts;

import { createTheme } from "@mui/material";
import baseTheme from "./base";
import darkPalette from "./palettes/dark";
import { NORD_COLORS } from "./palettes/colors";

const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    primary: darkPalette.primary,
    mode: "dark",
    text: darkPalette.text,
    background: darkPalette.background,
  },
  components: {
    ...baseTheme.components,
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: darkPalette.text.primary,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: darkPalette.text.primary,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected:hover": {
            backgroundColor: darkPalette.primary.main,
            color: darkPalette.primary.contrastText,
          },
          "&:hover": {
            backgroundColor: darkPalette.primary.main,
            color: darkPalette.primary.contrastText,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          color: darkPalette.primary.main,
          textDecoration: "none",
          "&:hover": {
            color: NORD_COLORS.NORD14,
          },
          "&:visited": {
            color: NORD_COLORS.NORD15,
            "&:hover": {
              color: NORD_COLORS.NORD14,
            },
          },
          "&:focus": {
            color: NORD_COLORS.NORD14,
            outline: `1px solid ${NORD_COLORS.NORD14}`,
            textDecoration: "underline",
          },
          "&:active": {
            color: NORD_COLORS.NORD12,
          },
        },
      },
    },
  },
});

export default darkTheme;

import { t, Trans } from "@lingui/macro";
import illustration from "../../../assets/pictures/illustrations/services/advanced-website.webp";
import { CustomerNeedsCardProps } from "../../technical/technology-card/interfaces";
import BASIC_WEBSITE from "./00-basic-website";

const ADVANCED_WEBSITE: CustomerNeedsCardProps = {
  id: "01-advanced-website",
  comingSoon: false,
  illustration,
  illustrationAlt: t`Get your interactive website or web application.`,
  order: 1,
  needsList: (
    <ul>
      <li>
        <Trans>
          Everything from <a href={`#${BASIC_WEBSITE.id}`}>a simple website</a>{" "}
          plus more advanced features.
        </Trans>
      </li>
      <li>
        <Trans>
          A space where users can log themselves in and manage their own
          profile.
        </Trans>
      </li>
      <li>
        <Trans>
          A place where you can promote your products or articles and update
          them when you want.
        </Trans>
      </li>
      <li>
        <Trans>A way to make your customer buy your products online.</Trans>
      </li>
      <li>
        <Trans>
          Generate documents, invoices, or any other type of file automatically.
        </Trans>
      </li>
      <li>
        <Trans>
          Smart reporting with graphs, statistics, and more to help you make
          informed decisions and track your progress over time.
        </Trans>
      </li>
      <li>
        <Trans>Hide complex actions behind a single button.</Trans>
      </li>
    </ul>
  ),
  servicesList: (
    <ul>
      <li>
        <Trans>
          A dynamic website that can change its content depending on the user
          actions, with filters, search bars and many more.
        </Trans>
      </li>
      <li>
        <Trans>
          A small e-commerce platform to sell your products or services online
          with a payment gateway integration.
        </Trans>
      </li>
      <li>
        <Trans>
          A User Interface suited to your needs while keeping the brand
          identity.
        </Trans>
      </li>
      <li>
        <Trans>
          A secure way for users to sign in, change their password, enable
          two-steps authentication and manage their profile.
        </Trans>
      </li>
      <li>
        <Trans>
          A back office to manage your content, users, and orders easily.
        </Trans>
      </li>
      <li>
        <Trans>
          A responsive design that looks good on all devices (smartphones,
          tablets, laptops, and desktops).
        </Trans>
      </li>
    </ul>
  ),
};

export default ADVANCED_WEBSITE;

import { Trans } from "@lingui/macro";
import { Alert } from "@mui/material";
import logo from "../../../assets/pictures/logos/technical/docker-logo.png";
import linuxLogo from "../../../assets/pictures/logos/technical/linux-logo.png";
import { TechnologyCardProps } from "../technology-card/interfaces";

const DOCKER: TechnologyCardProps = {
  title: "Docker",
  order: 2,
  logo,
  subTitle: "Containerization platform",
  detailLogos: [linuxLogo],
  latestUsedVersion: "27.3",
  url: "www.docker.com",
  favorite: true,
  description: (
    <Trans>
      <p>
        <strong>Docker</strong> is a powerful platform for creating, deploying,
        and managing containerized applications.
      </p>
      <h3>How we use it</h3>
      <p>
        We use Docker for almost every service to ensure isolation and
        consistency. Each service runs in its own container with all its
        dependencies installed, preventing any impact on the host machine. This
        isolation makes the services easy to run and maintain across different
        environments.
        <br />
        Docker is employed in both development and production, with production
        artifacts often being Docker images built by automated pipelines upon
        code commits. Additionally, we use Compose V2 to make small
        orchestration easier and maintainable.
      </p>
      <h3>Why we use it</h3>
      <p>
        <ul>
          <li>
            <strong>Popular</strong>: Widely adopted and supported.
          </li>
          <li>
            <strong>Usable on Almost Every Environment</strong>: Ensures
            application portability and reduces dependency on the host
            environment.
          </li>
          <li>
            <strong>Easy to Set Up and Upscale</strong>: Simplifies the
            deployment and scaling of applications.
          </li>
          <li>
            <strong>Automated Image Build via Pipelines</strong>: Streamlines
            the build and deployment process.
          </li>
        </ul>
      </p>
      <Alert severity="info">
        This website runs in an Nginx docker container which image has been
        built in an automated CI pipeline.
        <br />
        The production server hasn't got any code. Just a compose file and a
        read access to the container registry where the built image is stored.
      </Alert>
    </Trans>
  ),
};

export default DOCKER;

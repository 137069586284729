import { t, Trans } from "@lingui/macro";
import EmailIcon from "@mui/icons-material/Email";
import { Button, Grid2, Paper, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "motion/react";
import { Link } from "react-router-dom";
import customApplicationIllustration from "../../assets/pictures/illustrations/home/custom-application.webp";
import developmentIllustration from "../../assets/pictures/illustrations/home/development.webp";
import internalToolWebApplicationIllustration from "../../assets/pictures/illustrations/home/internal-tool-webapp.webp";
import multiDevicePlatformIllustration from "../../assets/pictures/illustrations/home/multi-device-platform.webp";
import qualityCodeIllustration from "../../assets/pictures/illustrations/home/quality-code.webp";
import softwareDevelopmentIllustration from "../../assets/pictures/illustrations/home/software-development.webp";
import BaseContainer from "../../shared/components/base/container";
import LogoSeparator from "../../shared/components/logo-separator";
import { CONTACT_MAIL } from "../../utils/consts";
import MOTION_PROPS from "../../utils/motions";
import PartnersCards from "./partners";
import style from "./style";
import DownloadCVButton from "../../shared/components/base/footer/download-cv";

const HomePage = () => {
  const theme = useTheme();
  const isLtMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      className="home-page"
      style={isLtMd ? style.containerLtMd : style.containerMd}
    >
      <BaseContainer>
        <motion.h1
          initial={MOTION_PROPS.TITLE.INITIAL}
          viewport={MOTION_PROPS.TITLE.VIEWPORT}
          whileInView={MOTION_PROPS.TITLE.WHILE_IN_VIEW}
          style={isLtMd ? style.titleLtMd : style.titleMd}
        >
          <Trans>What we do</Trans>
        </motion.h1>

        <motion.div
          initial={MOTION_PROPS.CONTAINER.INITIAL}
          viewport={MOTION_PROPS.CONTAINER.VIEWPORT}
          whileHover={MOTION_PROPS.CONTAINER.WHILE_HOVER}
          whileInView={MOTION_PROPS.CONTAINER.WHILE_IN_VIEW}
        >
          <Grid2 container spacing={2} sx={style.contactContainer}>
            <Grid2 size={{ xs: 12, md: 8 }} sx={style.contactPhrase}>
              <Trans>
                Ready to start your project? Contact us now to get a free quote.
              </Trans>
            </Grid2>

            <Grid2 size={{ xs: 12, md: 4 }} alignContent={"center"}>
              <a href={"mailto:" + CONTACT_MAIL}>
                <Button
                  variant="contained"
                  startIcon={<EmailIcon />}
                  style={style.button}
                  size={isLtMd ? "medium" : "large"}
                >
                  {CONTACT_MAIL}
                </Button>
              </a>
            </Grid2>
          </Grid2>
          <Paper style={style.paper}>
            <Grid2 container spacing={0}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <img
                  src={softwareDevelopmentIllustration}
                  alt={t`We create client-facing, internal or custom applications.`}
                  style={style.illustration}
                />
              </Grid2>
              <Grid2 size={{ xs: 0, md: 6 }}>
                <img
                  src={customApplicationIllustration}
                  alt={t`We ensure to make the application the most user-friendly as possible.`}
                  style={style.illustration}
                />
              </Grid2>

              <Grid2 size={12}>
                <div style={style.description}>
                  <div style={style.subTitle}>
                    <Trans>Web and Mobile Solutions</Trans>
                  </div>

                  <Trans>
                    If you need a{" "}
                    <Link
                      to={{ pathname: "/services", hash: "00-basic-website" }}
                      style={style.keyword}
                    >
                      simple website
                    </Link>{" "}
                    to promote your business that looks great on every device,
                    we can help.
                    <br />
                    We also create{" "}
                    <Link
                      to={{
                        pathname: "/services",
                        hash: "01-advanced-website",
                      }}
                      style={style.keyword}
                    >
                      advanced web applications
                    </Link>{" "}
                    where users can manage their own spaces.
                    <br />
                    Looking for a{" "}
                    <Link
                      to={{
                        pathname: "/services",
                        hash: "02-mobile-application",
                      }}
                      style={style.keyword}
                    >
                      mobile app
                    </Link>{" "}
                    that sends notifications? We’ve got you covered.
                  </Trans>
                </div>
              </Grid2>
            </Grid2>
          </Paper>
        </motion.div>

        <LogoSeparator />

        <motion.div
          initial={MOTION_PROPS.CONTAINER.INITIAL}
          viewport={MOTION_PROPS.CONTAINER.VIEWPORT}
          whileHover={MOTION_PROPS.CONTAINER.WHILE_HOVER}
          whileInView={MOTION_PROPS.CONTAINER.WHILE_IN_VIEW}
        >
          <Grid2 container spacing={2} sx={style.contactContainer}>
            <Grid2 size={{ xs: 12, md: 8 }} sx={style.contactPhrase}>
              <Trans>
                Looking for any IT-related solutions? We can help you with that.
              </Trans>
            </Grid2>

            <Grid2 size={{ xs: 12, md: 4 }} alignContent={"center"}>
              <a href={"mailto:" + CONTACT_MAIL}>
                <Button
                  variant="contained"
                  startIcon={<EmailIcon />}
                  style={style.button}
                  size={isLtMd ? "medium" : "large"}
                >
                  {CONTACT_MAIL}
                </Button>
              </a>
            </Grid2>
          </Grid2>

          <Paper style={style.paper}>
            <Grid2 container spacing={0}>
              <Grid2 size={{ xs: 0, md: 6 }}>
                <img
                  src={developmentIllustration}
                  alt={t`We work on the latest software development frameworks.`}
                  style={style.illustration}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <img
                  src={multiDevicePlatformIllustration}
                  alt={t`We make multi-device application which are mobile phone friendly.`}
                  style={style.illustration}
                />
              </Grid2>

              <Grid2 size={12}>
                <div style={style.description}>
                  <div style={style.subTitle}>
                    <Trans>Automation and Internal Tools</Trans>
                  </div>

                  <Trans>
                    We develop tailored{" "}
                    <Link
                      to={{
                        pathname: "/services",
                        hash: "03-internal-webtool",
                      }}
                      style={style.keyword}
                    >
                      internal tools
                    </Link>{" "}
                    to automate repetitive tasks, such as filling Excel sheets
                    or managing databases, with the simplicity of clicking a
                    button. Our solutions are built to save time and improve
                    efficiency, customized to your specific needs.
                  </Trans>
                </div>
              </Grid2>
            </Grid2>
          </Paper>
        </motion.div>

        <LogoSeparator />

        <motion.div
          initial={MOTION_PROPS.CONTAINER.INITIAL}
          viewport={MOTION_PROPS.CONTAINER.VIEWPORT}
          whileHover={MOTION_PROPS.CONTAINER.WHILE_HOVER}
          whileInView={MOTION_PROPS.CONTAINER.WHILE_IN_VIEW}
        >
          <Grid2 container spacing={2} sx={style.contactContainer}>
            <Grid2 size={{ xs: 12, md: 8 }} sx={style.contactPhrase}>
              <Trans>
                Seeking for a software developer? Consult our CV here.
              </Trans>
            </Grid2>

            <Grid2 size={{ xs: 12, md: 4 }} alignContent={"center"}>
              <DownloadCVButton style={style.button} />
            </Grid2>
          </Grid2>

          <Paper style={style.paper}>
            <Grid2 container spacing={0}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <img
                  src={internalToolWebApplicationIllustration}
                  alt={t`We suit the products to your needs.`}
                  style={style.illustration}
                />
              </Grid2>
              <Grid2 size={{ xs: 0, md: 6 }}>
                <img
                  src={qualityCodeIllustration}
                  alt={t`We make the product secure and optimized.`}
                  style={style.illustration}
                />
              </Grid2>

              <Grid2 size={12}>
                <div style={style.description}>
                  <div style={style.subTitle}>
                    <Trans>Collaboration and Expertise</Trans>
                  </div>

                  <Trans>
                    Need a{" "}
                    <Link
                      to={{
                        pathname: "/services",
                        hash: "04-software-development",
                      }}
                      style={style.keyword}
                    >
                      full-stack software developer
                    </Link>{" "}
                    on your team? We would be happy to join you.
                    <br />
                    From{" "}
                    <Link
                      to={{ pathname: "/services", hash: "05-teaching" }}
                      style={style.keyword}
                    >
                      high school IT teaching and coaching junior dev teams
                    </Link>{" "}
                    to delivering IT-related tasks, we’re here to provide
                    solutions that adhere to the highest coding standards for
                    easy maintenance and long-term reliability.
                  </Trans>
                </div>
              </Grid2>
            </Grid2>
          </Paper>
        </motion.div>

        <LogoSeparator />
      </BaseContainer>

      <PartnersCards />
    </div>
  );
};

export default HomePage;

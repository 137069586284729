import { t, Trans } from "@lingui/macro";
import SchoolIcon from "@mui/icons-material/School";
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";
import illustration from "../../../assets/pictures/illustrations/services/teaching.webp";
import { CustomerNeedsCardProps } from "../../technical/technology-card/interfaces";
import style from "./style";

const TEACHING: CustomerNeedsCardProps = {
  id: "05-teaching",
  comingSoon: false,
  illustration,
  illustrationAlt: t`Get your team or class to learn how to code.`,
  order: 5,
  needsList: (
    <ul>
      <li>
        <Trans>Teach your team or class how to code.</Trans>
      </li>
      <li>
        <Trans>
          Coach a junior development team to improve their skills and knowledge.
        </Trans>
      </li>
      <li>
        <Trans>
          Teach in highs schools or universities to share your knowledge and
          experience.
        </Trans>
      </li>
    </ul>
  ),
  servicesList: (
    <>
      <ul>
        <li>
          <Trans>
            Teach the basics of programming, web development, or software
            engineering.
          </Trans>
        </li>
        <li>
          <Trans>
            Teach how to use a specific programming language or framework
            mentioned on{" "}
            <Link to={{ pathname: "/technical" }}>our technical page</Link>.
            {/* FIXME: ROUTES does not work */}
          </Trans>
        </li>
        <li>
          <Trans>
            Share experience and best practices to improve the quality of the
            code.
          </Trans>
        </li>
      </ul>
      <Alert severity="success" style={style.alert} icon={<SchoolIcon />}>
        <Trans>
          We are proud to teach at the{" "}
          <a href="www.ephec.be">EPHEC High-School of Louvain-La-Neuve</a>.
        </Trans>
      </Alert>
    </>
  ),
};

export default TEACHING;

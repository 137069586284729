import { PartnerCardProps } from "../../../technical/technology-card/interfaces";
import logo from "../../../../assets/pictures/logos/home/partners/breast-international-group/global-security-europe.png";

const GLOBAL_SECURITY_EUROPE: PartnerCardProps = {
  name: "Global Security Europe",
  order: 2,
  logo,
  urls: {
    website: "https://www.gse.be/",
    facebook: "https://www.facebook.com/gsesecu/",
  },
};

export default GLOBAL_SECURITY_EUROPE;

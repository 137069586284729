import { Trans } from "@lingui/macro";
import { Grid2 } from "@mui/material";
import { motion } from "motion/react";
import BaseContainer from "../../../shared/components/base/container";
import MOTION_PROPS from "../../../utils/motions";
import { DATA } from "./data";
import PartnerCard from "./partner";
import style from "./style";

const PartnersCards = () => {
  const partners = DATA.sort(
    (partner, nextPartner) => partner.order - nextPartner.order,
  );

  return (
    <div className="Partners">
      <BaseContainer>
        <h1 style={style.title}>
          <Trans>Our partners</Trans>
        </h1>
        <Grid2 container spacing={2} justifyContent={"center"}>
          {partners.map((partner, index) => (
            <Grid2 size={{ xs: 10, md: 4 }} key={partner.name}>
              <motion.div
                initial={MOTION_PROPS.TITLE.INITIAL}
                viewport={MOTION_PROPS.TITLE.VIEWPORT}
                whileHover={MOTION_PROPS.CONTAINER.WHILE_HOVER}
                whileInView={MOTION_PROPS.TITLE.WHILE_IN_VIEW}
              >
                <PartnerCard
                  key={partner.name}
                  partner={partner}
                  index={index}
                />
              </motion.div>
            </Grid2>
          ))}
        </Grid2>
      </BaseContainer>
    </div>
  );
};

export default PartnersCards;

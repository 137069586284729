import { ThemeContext } from "@emotion/react";
import { Trans } from "@lingui/macro";
import CodeIcon from "@mui/icons-material/Code";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import { Paper, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useContext } from "react";
import darkLogo from "../../../../assets/pictures/logos/shared/dark/brand-logo.png";
import lightLogo from "../../../../assets/pictures/logos/shared/light/brand-logo.png";
import { ROUTES } from "../../../../utils/routes";
import BaseContainer from "../container";
import NavigationButton from "./navigation-button";
import style from "./style";

const NavigationBar = () => {
  const { darkMode } = useContext<object>(ThemeContext) as {
    darkMode: boolean;
  };

  const theme = useTheme();
  const isLtMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="NavigationBar">
      <BaseContainer>
        <Paper style={style.navigationContainer}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            alignItems="center"
            spacing={1}
          >
            {!isLtMd && (
              <img
                src={darkMode ? darkLogo : lightLogo}
                style={style.brandLogo}
              />
            )}
            <NavigationButton
              path={ROUTES.HOME}
              label={<Trans>What we do</Trans>}
              icon={<CorporateFareIcon />}
              fullWidth={isLtMd}
            />
            <NavigationButton
              path={ROUTES.SERVICES}
              label={<Trans>What you need</Trans>}
              icon={<PsychologyAltIcon />}
              fullWidth={isLtMd}
            />
            <NavigationButton
              path={ROUTES.TECHNICAL}
              label={<Trans>What we use</Trans>}
              icon={<CodeIcon />}
              fullWidth={isLtMd}
            />
          </Stack>
        </Paper>
      </BaseContainer>
    </div>
  );
};

export default NavigationBar;

import { PartnerCardProps } from "../../../technical/technology-card/interfaces";
import logo from "../../../../assets/pictures/logos/home/partners/breast-international-group/masro-solutions.png";

const MASRO_SOLUTIONS: PartnerCardProps = {
  name: "Masro Solutions",
  order: 1,
  logo,
  urls: {
    website: "https://masro.be/",
  },
};

export default MASRO_SOLUTIONS;

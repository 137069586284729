import { Trans } from "@lingui/macro";
import illustration from "../../../assets/pictures/illustrations/services/cloud-integration.webp";
import { CustomerNeedsCardProps } from "../../technical/technology-card/interfaces";

const CLOUD_INTEGRATION: CustomerNeedsCardProps = {
  id: "07-cloud-integration",
  comingSoon: true,
  illustration,
  illustrationAlt: "Get your cloud services integrated in your projects.",
  order: 7,
  needsList: (
    <ul>
      <li>
        <Trans>
          Integrate cloud services into your website or application.
        </Trans>
      </li>
      <li>
        <Trans>Store and manage data in the cloud.</Trans>
      </li>
      <li>
        <Trans>
          Improve your website or application performance with cloud services.
        </Trans>
      </li>
      <li>
        <Trans>Automate tasks and workflows with cloud services.</Trans>
      </li>
    </ul>
  ),
  servicesList: (
    <ul>
      <li>
        <Trans>
          Implement cloud storage solutions to store and manage data.
        </Trans>
      </li>
      <li>
        <Trans>
          Implement automated workflows with cloud services such as AWS, Azure
          or Ansible.
        </Trans>
      </li>
    </ul>
  ),
};

export default CLOUD_INTEGRATION;

import React from "react";
import fonts from "../../../utils/fonts";
import { CSSPropertiesDict } from "../../../utils/types";
import { NORD_COLORS } from "../../../themes/palettes/colors";

const backdropChildren: React.CSSProperties = {
  position: "relative",
  zIndex: 2,
};

const backdropContainer: React.CSSProperties = {
  position: "relative",
  width: "100%",
  height: "100%",
  zIndex: 2,
};

const backdropDiv: React.CSSProperties = {
  backgroundColor: "rgba(0, 0, 0, .5)",
  backdropFilter: "blur(5px)",
  height: "100%",
  left: 0,
  position: "absolute",
  top: 0,
  width: "100%",
  zIndex: 3,
};

const backdropText: React.CSSProperties = {
  alignContent: "center",
  color: NORD_COLORS.NORD4,
  fontFamily: fonts.default,
  fontSize: "2em",
  justifyContent: "center",
  height: "100%",
  margin: "auto",
  position: "absolute",
  textAlign: "center",
  width: "100%",
};

const container: React.CSSProperties = {
  margin: "1em auto",
};

const detailsContainer: React.CSSProperties = {
  padding: "0.5em",
};

const image: React.CSSProperties = {
  position: "absolute",
  objectFit: "cover",
  top: "50%",
  left: "50%",
  width: "100%",
  transform: "translate(-50%, -50%)",
};

const imageContainer: React.CSSProperties = {
  aspectRatio: 8 / 11,
  position: "relative",
  width: "100%",
  overflow: "hidden",
};

const listContainer: React.CSSProperties = {
  marginLeft: "-1em",
};

const needsDiv: React.CSSProperties = {
  border: "1px solid",
  padding: "0.5em",
  height: "100%",
};

const subTitle: React.CSSProperties = {
  marginBottom: "0",
  fontFamily: fonts.default,
  textAlign: "center",
};

const topic: React.CSSProperties = {
  fontFamily: fonts.monospace,
  fontStyle: "italic",
  fontSize: "1.3em",
};

const style: CSSPropertiesDict = {
  backdropChildren,
  backdropContainer,
  backdropDiv,
  backdropText,
  container,
  detailsContainer,
  image,
  imageContainer,
  listContainer,
  needsDiv,
  subTitle,
  topic,
};

export default style;

import { createTheme } from "@mui/material";
import { NORD_COLORS } from "./palettes/colors";
import { LOCAL_STORAGE_KEYS } from "../utils/consts";
import basePalette from "./palettes/base";

const baseTheme = createTheme({
  palette: {
    ...basePalette,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: basePalette.success.main,
          color: basePalette.success.contrastText,
        },
        filledWarning: {
          backgroundColor: basePalette.warning.main,
          color: basePalette.warning.contrastText,
        },
      },
    },
  },
});

export default baseTheme;

/**
 * Check is user used dark theme as last. If cannot retrieve last used theme,
 * browser preference is applied.
 *
 * @returns boolean - `true` if default theme should be dark. Light otherwise.
 */
export const isPreferredThemeDark = (): boolean => {
  const preferredTheme = localStorage.getItem(LOCAL_STORAGE_KEYS.THEME);

  if (preferredTheme) {
    return preferredTheme === "dark";
  }

  return window.matchMedia("(prefers-color-scheme: dark)").matches;
};

import { Trans } from "@lingui/macro";
import logo from "../../../assets/pictures/logos/technical/mysql-logo.png";
import { TechnologyCardProps } from "../technology-card/interfaces";

const MYSQL: TechnologyCardProps = {
  title: "MySQL",
  order: 11,
  logo,
  subTitle: "Relational database management system",
  detailLogos: [],
  latestUsedVersion: "8.0",
  url: "www.mysql.com",
  favorite: false,
  description: (
    <Trans>
      <p>
        <strong>MySQL</strong> is a widely-used relational database management
        system known for its robustness and flexibility.
      </p>
      <h3>How we use it</h3>
      <p>
        Even if we prefer working with PostgreSQL, we use MySQL for other
        projects. Queries for one can usually be adapted for the other. Django
        and FastAPI (through SQL Alchemy) support MySQL very well.
      </p>
      <p>
        <h3>Why we use it</h3>
        <ul>
          <li>
            <strong>Popular</strong>: MySQL is widely adopted, ensuring
            extensive community support.
          </li>
          <li>
            <strong>Similar to PostgreSQL</strong>: Comparable in many ways,
            allowing easier cross-utilization.
          </li>
          <li>
            <strong>Good Support on Python Frameworks</strong>: Integrates
            smoothly with our preferred Python frameworks.
          </li>
        </ul>
      </p>
    </Trans>
  ),
};

export default MYSQL;
